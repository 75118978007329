// extracted by mini-css-extract-plugin
export var column = "DetailVideos__column__UZj12";
export var flex = "DetailVideos__flex__tukqp";
export var flexColumn = "DetailVideos__flexColumn__S9Zee";
export var gap1 = "DetailVideos__gap1__NfIEo";
export var gap2 = "DetailVideos__gap2___iUg_";
export var gap3 = "DetailVideos__gap3__XvlEn";
export var gap4 = "DetailVideos__gap4__ccMb2";
export var gap5 = "DetailVideos__gap5__J8eR2";
export var playControlContainer = "DetailVideos__playControlContainer__yDxoB";
export var row = "DetailVideos__row__Fsh24";
export var thumbnail = "DetailVideos__thumbnail__l2g4X";
export var videoItem = "DetailVideos__videoItem__lPfh5";
export var videosContainer = "DetailVideos__videosContainer__Rr79O";
export var visible = "DetailVideos__visible__SRrCg";