// extracted by mini-css-extract-plugin
export var column = "ProductSlider__column__Gb34T";
export var flex = "ProductSlider__flex__G3BZa";
export var flexColumn = "ProductSlider__flexColumn__P2RKw";
export var gap1 = "ProductSlider__gap1__gGoJZ";
export var gap2 = "ProductSlider__gap2__FGQBF";
export var gap3 = "ProductSlider__gap3__mRCV4";
export var gap4 = "ProductSlider__gap4__wPm3x";
export var gap5 = "ProductSlider__gap5__wSxgk";
export var product = "ProductSlider__product__wA6Jq";
export var row = "ProductSlider__row__fTCuS";
export var slickSliderOverride = "ProductSlider__slickSliderOverride__ciLbE";
export var sliderContainer = "ProductSlider__sliderContainer__XvPsT";