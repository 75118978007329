// extracted by mini-css-extract-plugin
export var button = "DetailsSidebar__button__m2hXo";
export var buttonIcon = "DetailsSidebar__buttonIcon__IldhN";
export var buttonRfp = "DetailsSidebar__buttonRFP__jJhGV";
export var column = "DetailsSidebar__column__tIVny";
export var detailsSideBar = "DetailsSidebar__detailsSideBar__leffo";
export var finePrint = "DetailsSidebar__finePrint__eEU2q";
export var flex = "DetailsSidebar__flex__ZpfSh";
export var flexColumn = "DetailsSidebar__flexColumn__Cnc_S";
export var gap1 = "DetailsSidebar__gap1__s4789";
export var gap2 = "DetailsSidebar__gap2__TsHHb";
export var gap3 = "DetailsSidebar__gap3__j4Tuy";
export var gap4 = "DetailsSidebar__gap4__n_4g0";
export var gap5 = "DetailsSidebar__gap5__QO_6J";
export var row = "DetailsSidebar__row__L5N94";