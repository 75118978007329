// extracted by mini-css-extract-plugin
export var bannerText = "ProductDetailsForSidebar__bannerText__lDPVO";
export var column = "ProductDetailsForSidebar__column__WUmBm";
export var detailsContainer = "ProductDetailsForSidebar__detailsContainer__evA_1";
export var divider = "ProductDetailsForSidebar__divider__CALKH";
export var exclusiveBanner = "ProductDetailsForSidebar__exclusiveBanner__sIaCC";
export var finePrint = "ProductDetailsForSidebar__finePrint__jXWsI";
export var flex = "ProductDetailsForSidebar__flex__Vtyk1";
export var flexColumn = "ProductDetailsForSidebar__flexColumn__vi8l5";
export var gap1 = "ProductDetailsForSidebar__gap1__GUMNR";
export var gap2 = "ProductDetailsForSidebar__gap2__NrLlK";
export var gap3 = "ProductDetailsForSidebar__gap3__bVrCf";
export var gap4 = "ProductDetailsForSidebar__gap4__kbVZy";
export var gap5 = "ProductDetailsForSidebar__gap5__uLDGJ";
export var hideDetails = "ProductDetailsForSidebar__hideDetails__ZZkyA";
export var logo = "ProductDetailsForSidebar__logo__tqBWV";
export var logoContainer = "ProductDetailsForSidebar__logoContainer__Jrzh8";
export var overflow = "ProductDetailsForSidebar__overflow___JQJR";
export var perMonth = "ProductDetailsForSidebar__perMonth__AtJ0b";
export var pricing = "ProductDetailsForSidebar__pricing__nUYtM";
export var productInfo = "ProductDetailsForSidebar__productInfo__tUy62";
export var productName = "ProductDetailsForSidebar__productName__qd51D";
export var row = "ProductDetailsForSidebar__row__sZCUl";
export var saleEndDate = "ProductDetailsForSidebar__saleEndDate__kJ_4V";
export var showDetails = "ProductDetailsForSidebar__showDetails__kOmFo";
export var specCategory = "ProductDetailsForSidebar__specCategory__nuqUy";
export var specItem = "ProductDetailsForSidebar__specItem__fJ1DJ";
export var specsContainer = "ProductDetailsForSidebar__specsContainer__cdObe";
export var specsRow = "ProductDetailsForSidebar__specsRow__zDEgH";