// extracted by mini-css-extract-plugin
export var column = "ProductDescription__column__tBG4z";
export var featureDescription = "ProductDescription__featureDescription___wo28";
export var featureFlexContainer = "ProductDescription__featureFlexContainer__oTtl7";
export var featureMargin = "ProductDescription__featureMargin___mHHc";
export var featureTitle = "ProductDescription__featureTitle__NgtHR";
export var features = "ProductDescription__features__jdTx3";
export var flex = "ProductDescription__flex__Z70I1";
export var flexColumn = "ProductDescription__flexColumn__i4c46";
export var gap1 = "ProductDescription__gap1__u7kBE";
export var gap2 = "ProductDescription__gap2__SwcB6";
export var gap3 = "ProductDescription__gap3__oafgr";
export var gap4 = "ProductDescription__gap4__RsuT5";
export var gap5 = "ProductDescription__gap5__zVJbK";
export var productDescription = "ProductDescription__productDescription__ohhF3";
export var productDescriptionContainer = "ProductDescription__productDescriptionContainer__XkMMG";
export var productTitle = "ProductDescription__productTitle__bYcYi";
export var row = "ProductDescription__row__jhzH1";