// extracted by mini-css-extract-plugin
export var activeDot = "VideoCarousel__activeDot__slX8P";
export var closeOverlayButton = "VideoCarousel__closeOverlayButton__vvfrS";
export var column = "VideoCarousel__column__mJDIY";
export var dotControl = "VideoCarousel__dotControl__q057a";
export var dotsContainer = "VideoCarousel__dotsContainer__MU1Dk";
export var flex = "VideoCarousel__flex__VF2Wc";
export var flexColumn = "VideoCarousel__flexColumn__GhNxt";
export var gap1 = "VideoCarousel__gap1__RRYaZ";
export var gap2 = "VideoCarousel__gap2__h_HcT";
export var gap3 = "VideoCarousel__gap3__u6EpH";
export var gap4 = "VideoCarousel__gap4___sBFK";
export var gap5 = "VideoCarousel__gap5__vTbXd";
export var innerOverlay = "VideoCarousel__innerOverlay__HFIEh";
export var overlay = "VideoCarousel__overlay__x8e6n";
export var playControlContainer = "VideoCarousel__playControlContainer__kff6d";
export var row = "VideoCarousel__row__zlupG";
export var thumbSlide = "VideoCarousel__thumbSlide__ImKFc";
export var thumbnail = "VideoCarousel__thumbnail__kKQcZ";
export var videoDescription = "VideoCarousel__videoDescription__WvMeM";
export var videoPlayer = "VideoCarousel__videoPlayer__ntlTA";
export var vimeoPlayer = "VideoCarousel__vimeoPlayer__lPPMi";