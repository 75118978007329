// extracted by mini-css-extract-plugin
export var column = "VideoPlayer__column__nRwoF";
export var flex = "VideoPlayer__flex__sCMSe";
export var flexColumn = "VideoPlayer__flexColumn__hfDjD";
export var gap1 = "VideoPlayer__gap1__QUDXz";
export var gap2 = "VideoPlayer__gap2__thft3";
export var gap3 = "VideoPlayer__gap3__fSBej";
export var gap4 = "VideoPlayer__gap4__ilPH_";
export var gap5 = "VideoPlayer__gap5__sKL9Q";
export var responsiveVideoPlayer = "VideoPlayer__responsiveVideoPlayer__dX_WG";
export var row = "VideoPlayer__row__JPJ41";
export var videoPlayer = "VideoPlayer__videoPlayer__xkdkn";
export var vimeoPlayer = "VideoPlayer__vimeoPlayer__CFene";